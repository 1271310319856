// TODO: Explicit import should ideally be refactored away

import Layout3 from '@/components/layouts/Layout3.vue';
import PasswordInput from '@/components/PasswordInput.vue';
export default defineComponent({
  name: 'SetPassword',
  components: {
    Layout3: Layout3,
    PasswordInput: PasswordInput
  },
  props: {
    image: Object,
    title: String
  },
  data: function data() {
    return {
      password: '',
      passwordCompliant: false
    };
  }
});